.react-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: scroll;
  padding-left: 5px;
  padding-top: 5px;
  max-height: 10rem;
}

.react-tagsinput--focused {
  border-color: $primary;
}

.react-tagsinput-tag {
  background-color: $primary;
  border-radius: 2px;
  border: 1px solid $primary;
  color: $primary-invert;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  // margin-top: 5px;
  margin-right: 5px;
  padding: 5px;
}

.react-tagsinput-tag a::before {
  content: " ×";
  color: $primary-invert;
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 1px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  //width: 80px;
}
