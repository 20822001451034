@import "./colors";

$primary: $base !default;
$link: $base;

$success: $green6 !default;

$info: $blue6;
$success: $green6;
$warning: $yellow5;
$danger: $red6;

$card-header-background-color: $base;
$card-header-color: $gray0;

$family-sans-serif: "Roboto", BlinkMacSystemFont, -apple-system, "Segoe UI",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  "Helvetica", "Arial", sans-serif;

// $body-size: 14px;

$footer-padding: 3rem 1.5rem 1rem;
