$black: #433c4d;
$gray0: #f9f9fa;
$gray1: #eeecf0;
$gray2: #e2dfe5;
$gray3: #d4d1da;
$gray4: #c6c1cd;
$gray5: #b6b0bf;
$gray6: #a49cb0;
$gray7: #8e849d;
$gray8: #726684;
$gray9: #433c4d;
$violet0: #f0eafa;
$violet1: #e0d3f4;
$violet2: #ceb9ee;
$violet3: #b899e7;
$violet4: #9b6edd;
$violet5: #6622cc;
$violet6: #5b1eb7;
$violet7: #4f1a9f;
$violet8: #411582;
$violet9: #2d0f5a;
$fuschia0: #f8eafa;
$fuschia1: #f1d3f4;
$fuschia2: #e9b8ee;
$fuschia3: #df98e7;
$fuschia4: #d26ddd;
$fuschia5: #bb22cc;
$fuschia6: #a81eb8;
$fuschia7: #931aa1;
$fuschia8: #7a1685;
$fuschia9: #560f5e;
$pink0: #faeaf3;
$pink1: #f4d3e7;
$pink2: #eeb8d9;
$pink3: #e798c7;
$pink4: #dd6eb0;
$pink5: #cc2288;
$pink6: #b81e7a;
$pink7: #a11a6b;
$pink8: #851658;
$pink9: #5e0f3f;
$red0: #faeaec;
$red1: #f5d3d7;
$red2: #efb9bf;
$red3: #e799a1;
$red4: #dd6f7a;
$red5: #cc2233;
$red6: #b81e2e;
$red7: #a11a28;
$red8: #851621;
$red9: #5e0f17;
$orange0: #f9ede6;
$orange1: #f3dbcb;
$orange2: #ecc6ac;
$orange3: #e3ad89;
$orange4: #d98f5d;
$orange5: #cc6622;
$orange6: #b85c1e;
$orange7: #a1501a;
$orange8: #844216;
$orange9: #5e2f0f;
$yellow0: #f8f6e1;
$yellow1: #f1ecc2;
$yellow2: #e9e1a0;
$yellow3: #e0d67b;
$yellow4: #d6c951;
$yellow5: #ccbb22;
$yellow6: #b8a91e;
$yellow7: #a1941a;
$yellow8: #867b16;
$yellow9: #605810;
$lime0: #eff8e2;
$lime1: #dff1c3;
$lime2: #cde9a2;
$lime3: #b9e17d;
$lime4: #a2d753;
$lime5: #88cc22;
$lime6: #7bb81e;
$lime7: #6ca21b;
$lime8: #598616;
$lime9: #406110;
$green0: #e7f9e5;
$green1: #cef2c9;
$green2: #b1ebaa;
$green3: #8fe386;
$green4: #67d95a;
$green5: #33cc22;
$green6: #2eb81e;
$green7: #28a21b;
$green8: #218616;
$green9: #186110;
$teal0: #e5f9ed;
$teal1: #c9f2da;
$teal2: #aaebc4;
$teal3: #85e3ab;
$teal4: #5ad88c;
$teal5: #22cc66;
$teal6: #1eb85c;
$teal7: #1ba251;
$teal8: #168643;
$teal9: #106130;
$cyan0: #e5f9f7;
$cyan1: #c8f2ee;
$cyan2: #a8ebe4;
$cyan3: #84e2d9;
$cyan4: #58d8cb;
$cyan5: #22ccbb;
$cyan6: #1eb8a9;
$cyan7: #1ba294;
$cyan8: #16867b;
$cyan9: #106159;
$blue0: #e6f1f9;
$blue1: #cae2f2;
$blue2: #acd2eb;
$blue3: #88bfe3;
$blue4: #5ca7d9;
$blue5: #2288cc;
$blue6: #1e7ab8;
$blue7: #1a6ba0;
$blue8: #165884;
$blue9: #0f3e5e;
$indigo0: #eaecfa;
$indigo1: #d3d6f4;
$indigo2: #b9beee;
$indigo3: #99a0e7;
$indigo4: #6e7add;
$indigo5: #2233cc;
$indigo6: #1e2db7;
$indigo7: #1a279f;
$indigo8: #152081;
$indigo9: #0e1659;

$base: $violet6;

:export {
  gray0: #f9f9fa;
  gray1: #eeecf0;
  gray2: #e2dfe5;
  gray3: #d4d1da;
  gray4: #c6c1cd;
  gray5: #b6b0bf;
  gray6: #a49cb0;
  gray7: #8e849d;
  gray8: #726684;
  gray9: #433c4d;
  violet0: #f0eafa;
  violet1: #e0d3f4;
  violet2: #ceb9ee;
  violet3: #b899e7;
  violet4: #9b6edd;
  violet5: #6622cc;
  violet6: #5b1eb7;
  violet7: #4f1a9f;
  violet8: #411582;
  violet9: #2d0f5a;
  fuschia0: #f8eafa;
  fuschia1: #f1d3f4;
  fuschia2: #e9b8ee;
  fuschia3: #df98e7;
  fuschia4: #d26ddd;
  fuschia5: #bb22cc;
  fuschia6: #a81eb8;
  fuschia7: #931aa1;
  fuschia8: #7a1685;
  fuschia9: #560f5e;
  pink0: #faeaf3;
  pink1: #f4d3e7;
  pink2: #eeb8d9;
  pink3: #e798c7;
  pink4: #dd6eb0;
  pink5: #cc2288;
  pink6: #b81e7a;
  pink7: #a11a6b;
  pink8: #851658;
  pink9: #5e0f3f;
  red0: #faeaec;
  red1: #f5d3d7;
  red2: #efb9bf;
  red3: #e799a1;
  red4: #dd6f7a;
  red5: #cc2233;
  red6: #b81e2e;
  red7: #a11a28;
  red8: #851621;
  red9: #5e0f17;
  orange0: #f9ede6;
  orange1: #f3dbcb;
  orange2: #ecc6ac;
  orange3: #e3ad89;
  orange4: #d98f5d;
  orange5: #cc6622;
  orange6: #b85c1e;
  orange7: #a1501a;
  orange8: #844216;
  orange9: #5e2f0f;
  yellow0: #f8f6e1;
  yellow1: #f1ecc2;
  yellow2: #e9e1a0;
  yellow3: #e0d67b;
  yellow4: #d6c951;
  yellow5: #ccbb22;
  yellow6: #b8a91e;
  yellow7: #a1941a;
  yellow8: #867b16;
  yellow9: #605810;
  lime0: #eff8e2;
  lime1: #dff1c3;
  lime2: #cde9a2;
  lime3: #b9e17d;
  lime4: #a2d753;
  lime5: #88cc22;
  lime6: #7bb81e;
  lime7: #6ca21b;
  lime8: #598616;
  lime9: #406110;
  green0: #e7f9e5;
  green1: #cef2c9;
  green2: #b1ebaa;
  green3: #8fe386;
  green4: #67d95a;
  green5: #33cc22;
  green6: #2eb81e;
  green7: #28a21b;
  green8: #218616;
  green9: #186110;
  teal0: #e5f9ed;
  teal1: #c9f2da;
  teal2: #aaebc4;
  teal3: #85e3ab;
  teal4: #5ad88c;
  teal5: #22cc66;
  teal6: #1eb85c;
  teal7: #1ba251;
  teal8: #168643;
  teal9: #106130;
  cyan0: #e5f9f7;
  cyan1: #c8f2ee;
  cyan2: #a8ebe4;
  cyan3: #84e2d9;
  cyan4: #58d8cb;
  cyan5: #22ccbb;
  cyan6: #1eb8a9;
  cyan7: #1ba294;
  cyan8: #16867b;
  cyan9: #106159;
  blue0: #e6f1f9;
  blue1: #cae2f2;
  blue2: #acd2eb;
  blue3: #88bfe3;
  blue4: #5ca7d9;
  blue5: #2288cc;
  blue6: #1e7ab8;
  blue7: #1a6ba0;
  blue8: #165884;
  blue9: #0f3e5e;
  indigo0: #eaecfa;
  indigo1: #d3d6f4;
  indigo2: #b9beee;
  indigo3: #99a0e7;
  indigo4: #6e7add;
  indigo5: #2233cc;
  indigo6: #1e2db7;
  indigo7: #1a279f;
  indigo8: #152081;
  indigo9: #0e1659;
}
