@import "./variables";

div.bottom-border > div:not(:last-child) {
  border-bottom: 0.2px solid #d3d3d3;
}

div.bottom-border > div {
  padding-bottom: 1em;
  margin-top: 1rem;
}

div.right-border > div:not(:last-child) {
  border-right: 0.2px solid #d3d3d3;
}

div.right-border > div {
  margin-right: 1em;
}

.is-link {
  color: $primary;
  cursor: pointer;
  text-decoration: none;
}

.input {
  height: 100%;
}

.pagination {
  padding: 1rem;
}

.tabs a.is-active {
  border-bottom-color: #3273dc;
  color: #3273dc;
}
