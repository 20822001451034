.upload-file-item {
  display: inline-block;
  padding: 0;
  min-width: 150px;
  border: 1px solid #3273dc;
  border-radius: 3px;
  background: white;
  margin: 0 1rem 1rem 0;
}

.upload-file-remove {
  display: inline-block;
  padding: 0.75rem;
  border-right: 1px solid #3273dc;
  color: #3273dc;
  font-weight: bold;
  align-items: center;
}

.upload-file-remove:hover {
  cursor: pointer;
  background: #3273dc;
  color: white;
}

.upload-file-name {
  display: inline-block;
  padding: 0.75rem;
  color: #3273dc;
  border: none;
}

.upload-file-name-div {
  display: flex;
  border-bottom: 1px solid #3273dc;
}

.upload-file-size {
  display: inline-block;
  padding: 0.5rem;
  color: #7a7a7a;
}
